<template>
  <div class="card">
    <div class="card-body" v-if="!toggle">
      <span class="card-text">
        <div class="">
          <span class="h6">{{item.name}}</span>

          <fa
            v-if="isEdit"
            icon="arrow-down"
            class="text-primary pointer ml-2"
            @click="moveData(item.id, 1)">
          </fa>
          <fa
            v-if="isEdit"
            icon="arrow-up"
            class="text-primary pointer ml-2"
            @click="moveData(item.id, -1)">
          </fa>

          <span
            v-if="isEdit"
            class="float-right px-2 pointer text-success"
            @click="addCardForm">
            <fa icon="plus"></fa>
          </span>
          <span
            v-if="isEdit"
            class="px-2 pointer float-right"
            @click="toggle = !toggle">
            <fa icon="pencil-alt" class="text-warning"></fa>
          </span>
        </div>

        <DetailGroupItem
          class="mt-3"
          v-for="(card, cIdx) in item.children"
          :idx="cIdx"
          :key="card.id"
          :item="card"
          :parentId="item.id"
          :docType="docType"
          :templateType="templateType"
          :isEdit="isEdit"
          @updated="cardUpdated"
          @destroyed="cardUpdated">
        </DetailGroupItem>

        <DetailGroupItem
          key="form"
          class="mt-3"
          v-if="isCardAdd"
          :isForm="isCardForm"
          :item="cardData"
          :parentId="item.id"
          :idx="-1"
          :docType="docType"
          :templateType="templateType"
          :isEdit="isEdit"
          @created="cardUpdated"
          @cancel="cancelCardForm">
        </DetailGroupItem>
      </span>
    </div>

    <div class="card-body" v-if="toggle">
      <div class="card-text">
        <div class="form-row">
          <sgv-input-text
            class="col-12"
            label="ชื่อ"
            v-model="formData.name"
            :validations="[
              {text: 'required!', value: $v.formData.name.$dirty && $v.formData.name.$error}
            ]">
          </sgv-input-text>
        </div>

        <button
          v-if="isForm"
          type="button"
          class="btn btn-success"
          @click="createData">
          เพิ่ม
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-warning"
          @click="updateData">
          แก้ไข
        </button>

        <button
          v-if="toggle"
          type="button"
          class="btn btn-primary ml-2"
          @click="cancelData">
          ยกเลิก
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-danger ml-2 float-right"
          @click="destroyData(item.id)">
          ลบ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DetailGroupItem from './DetailGroupItem'
import {
  CREATE_GROUP,
  UPDATE_GROUP,
  DESTROY_GROUP,
  MOVE_GROUP
} from './graph'

export default {
  props: {
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    idx: {
      type: Number,
      required: true
    },
    parentId: {
      type: Number,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    isForm: {
      type: Boolean,
      required: false
    },
    isEdit: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      toggle: false,
      formData: {
        name: '',
      },
      isCardAdd: false,
      isCardForm: false,
      cardData: {},
    }
  },
  validations: {
    formData: {
      name: {required},
    }
  },
  methods: {
    serializeInput (formData) {
      return formData
    },
    createData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$apollo.mutate({
        mutation: CREATE_GROUP(this.templateType),
        variables: {
          docType: this.docType,
          parentId: this.parentId,
          input: this.serializeInput(this.formData)
        }
      })
      .then(res => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$emit('created', res.data.item)
        this.toggle = false
      })
      .catch(this.$toasted.global.error)
    },
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: UPDATE_GROUP(this.templateType),
        variables: {
          docType: this.docType,
          id: this.item.id,
          input
        }
      })
      .then(res => {
        this.$toasted.global.success("แก้ไขสำเร็จ")
        this.$emit('updated', res.data.item)
        this.toggle = false
      })
      .catch(this.$toasted.global.error)
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_GROUP(this.templateType),
        variables: {
          docType: this.docType,
          id: this.item.id
        }
      })
      .then(res => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.$emit('destroyed', res.data.item)
        this.toggle = false
      })
      .catch(this.$toasted.global.error)
    },
    moveData (id, step) {
      this.$apollo.mutate({
        mutation: MOVE_GROUP(this.templateType),
        variables: {
          docType: this.docType,
          id,
          step
        }
      })
      .then(res => {
        this.$toasted.global.success("แก้ไขสำเร็จ")
        this.$emit('updated', res.data.item)
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    cancelData () {
      this.toggle = false
      this.$emit('cancel', true)
    },
    addCardForm () {
      if (this.isCardForm) return

      this.isCardAdd = true
      this.cardData = {}
      this.isCardForm = true
    },
    cancelCardForm () {
      this.isCardAdd = false
      this.cardData = {}
      this.isCardForm = false
    },
    cardUpdated (payload) {
      this.cancelCardForm()
      this.$emit('updated', payload)
    },
    clearFormData () {
      this.formData.name = ''
    },
  },
  watch: {
    toggle (value) {
      if (value && !this.isForm) {
        this.$nextTick(() => {
          this.formData.name = this.item.name
        })
      } else {
        this.clearFormData()
      }
    }
  },
  created () {
    if (this.isForm) this.toggle = true
  },
  components: {
    DetailGroupItem
  }
}
</script>

<style lang="css" scoped>
</style>
