<template>
  <div>
    <DetailGroup
      class="mb-3"
      v-for="(child, idx) in children"
      :idx="idx"
      :key="child.id"
      :item="child"
      :docType="docType"
      :templateType="templateType"
      :parentId="parentId"
      :isEdit="isEdit"
      @updated="childUpdated"
      @destroyed="childUpdated">
    </DetailGroup>

    <DetailGroup
      key="form"
      class="mb-3"
      v-if="isChildAdd"
      :isForm="isChildForm"
      :item="childData"
      :parentId="parentId"
      :idx="-1"
      :docType="docType"
      :templateType="templateType"
      :isEdit="isEdit"
      @created="childUpdated"
      @cancel="cancelChildForm">
    </DetailGroup>

    <div class="">
      <button
        v-if="parentId > 0 && isEdit"
        type="button"
        class="btn btn-success"
        @click="addChildForm">
        เพิ่มรายการ
      </button>
    </div>
  </div>
</template>

<script>
import DetailGroup from './DetailGroup.vue'

export default {
  props: {
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    parentId: {
      type: Number,
      required: true
    },
    children: {
      type: Array,
      required: true
    },
    isEdit: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      isChildAdd: false,
      isChildForm: false,
      childData: {},
    }
  },
  methods: {
    addChildForm () {
      if (this.isChildForm) return

      this.isChildAdd = true
      this.childData = {}
      this.isChildForm = true
    },
    cancelChildForm () {
      this.isChildAdd = false
      this.childData = {}
      this.isChildForm = false
    },
    childUpdated () {
      this.cancelChildForm()
      this.$emit('updated', null)
    }
  },
  components: {
    DetailGroup
  }
}
</script>

<style lang="css" scoped>
</style>
