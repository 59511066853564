import { render, staticRenderFns } from "./DetailGroupItem.vue?vue&type=template&id=41fbc512&scoped=true&"
import script from "./DetailGroupItem.vue?vue&type=script&lang=js&"
export * from "./DetailGroupItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41fbc512",
  null
  
)

export default component.exports