<template>
  <div class="">
    <div class="" v-if="!toggle">
      <span class="">
        <span
          v-if="isEdit"
          class="mx-1 pointer float-right"
          @click="toggle = !toggle">
          <fa icon="pencil-alt" class="text-warning"></fa>
        </span>

        <div class="">
          <span>
            {{idx+1}}. {{item.name}}
          </span>

          <fa
            v-if="isEdit"
            icon="arrow-down"
            class="text-primary pointer ml-2"
            @click="moveData(item.id, 1)">
          </fa>
          <fa
            v-if="isEdit"
            icon="arrow-up"
            class="text-primary pointer ml-2"
            @click="moveData(item.id, -1)">
          </fa>

          <div class="row mt-1">
            <div
              v-if="item.formType === 'between'"
              class="col-12">
              <span class="text-danger">
                ต่ำสุด: {{item.minValue}}
              </span>
              <span class="text-warning px-3">
                ค่าเริ่มต้น: {{item.actualValue}}
              </span>
              <span class="text-success">
                สูงสุด: {{item.maxValue}}
              </span>
            </div>

            <div
              v-if="item.cron"
              class="col-12">
              ความถี่ (cron): {{item.cron}}
            </div>

            <div
              v-if="item.instrumentCode"
              class="col-12">
              เครื่องมือวัด: {{item.instrumentCode}}
            </div>

            <div
              v-if="item.device"
              class="col-12 text-warning">
              อุปกรณ์ IoT: {{item.device.code}} ({{item.device.name}}) ({{item.deviceTopic}})
            </div>

            <div
              v-if="item.formType === 'choice' && item.choices && item.choices.length > 0"
              class="col-12 mt-2">
              <sgv-input-check-array
                :options="item.choices"
                inline
                select="value"
                class="mb-0"
                :value="[item.actualValue]">
                <template slot-scope="option">
                  <span :class="{'text-success': item.actualValue === option.value}">
                    {{option.text}} ({{option.value}})
                  </span>
                </template>
              </sgv-input-check-array>
            </div>
          </div>
        </div>
      </span>
    </div>

    <div class="" v-if="toggle">
      <div class="">
        <div class="form-row">
          <sgv-input-text
            class="col-12"
            label="ชื่อ"
            v-model="formData.name">
          </sgv-input-text>

          <sgv-input-text
            class="col-12 col-sm-6"
            label="ความถี่ (cron)"
            v-model="formData.cron">
          </sgv-input-text>

          <sgv-input-text
            class="col-12 col-sm-6"
            label="เครื่องมือวัด"
            v-model="formData.instrumentCode">
          </sgv-input-text>

          <DetailGroupItemDeviceInput
            ref="device"
            class="col-12 col-md-9"
            label="อุปกรณ์ IoT"
            :templateType="templateType"
            :docType="docType"
            select="id"
            v-model="formData.deviceId">
          </DetailGroupItemDeviceInput>

          <sgv-input-text
            class="col-12 col-md-3"
            label="หัวข้อ (Topic)"
            placeholder="Ex. Q1"
            v-model="formData.deviceTopic">
          </sgv-input-text>
        </div>

        <div class="form-row">
          <sgv-input-select
            class="col-12"
            label="ประเภท"
            :options="formTypes"
            select="value"
            v-model="formData.formType">
            <template slot-scope="option">
              {{option.text}}
            </template>
          </sgv-input-select>
        </div>

        <div class="form-row">
          <sgv-input-number
            label="น้อยสุด"
            minus
            :precision="2"
            v-model="formData.minValue"
            class="col-4"
            :validations="[
              {text: 'required!', value: !this.$v.formData.minValue.required && this.$v.formData.$dirty}
            ]">
          </sgv-input-number>
          <sgv-input-number
            label="ค่าเริ่มต้น"
            minus
            :precision="2"
            v-model="formData.actualValue"
            class="col-4"
            :validations="[
              {text: 'required!', value: !this.$v.formData.actualValue.required && this.$v.formData.$dirty}
            ]">
          </sgv-input-number>
          <sgv-input-number
            label="มากสุด"
            minus
            :precision="2"
            v-model="formData.maxValue"
            class="col-4"
            :validations="[
              {text: 'required!', value: !this.$v.formData.maxValue.required && this.$v.formData.$dirty}
            ]">
          </sgv-input-number>
        </div>

        <div v-if="formData.formType === 'choice'" class="form-row">
          <sgv-input-text
            label="ชื่อตัวเลือก"
            placeholder="ชื่อตัวเลือก..."
            v-model="choiceFormData.text"
            class="col-6"
            :validations="[
              {text: 'required!', value: !choiceFormData.text.required && choiceFormData.$dirty}
            ]">
          </sgv-input-text>

          <sgv-input-number
            label="ค่าตัวเลข"
            minus
            placeholder="ค่าตัวเลข..."
            v-model="choiceFormData.value"
            class="col-6"
            :validations="[
              {text: 'required!', value: !choiceFormData.value.required && choiceFormData.$dirty}
            ]">
          </sgv-input-number>
        </div>

        <div v-if="formData.formType === 'choice'">
          <button
            type="button"
            class="btn btn-success"
            @click="createChoice">
            เพิ่มตัวเลือก
          </button>

          <ul class="mt-3">
            <li v-for="(choice, index) in choices" :key="choice.text">
              <fa
                icon="trash"
                class="text-danger"
                @click="destroyChoice(index)">
              </fa>
              {{choice.text}} ({{choice.value}})
            </li>
          </ul>
        </div>

        <button
          v-if="isForm"
          type="button"
          class="btn btn-success"
          @click="createData">
          เพิ่ม
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-warning"
          @click="updateData">
          แก้ไข
        </button>

        <button
          v-if="toggle"
          type="button"
          class="btn btn-primary ml-2"
          @click="cancelData">
          ยกเลิก
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-danger ml-2 float-right"
          @click="destroyData(item.id)">
          ลบ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DetailGroupItemDeviceInput from './DetailGroupItemDeviceInput'
import {
  CREATE_GROUP_ITEM,
  UPDATE_GROUP_ITEM,
  DESTROY_GROUP_ITEM,
  MOVE_GROUP_ITEM
} from './graph'

export default {
  props: {
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    idx: {
      type: Number,
      required: true
    },
    parentId: {
      type: Number,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    isForm: {
      type: Boolean,
      required: false
    },
    isEdit: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      toggle: false,
      formTypes: [
        {text: 'ตัวเลือก', value: 'choice'},
        {text: 'ระหว่าง', value: 'between'},
      ],
      choiceFormData: {
        text: '',
        value: 0
      },
      choices: [],
      formData: {
        name: '',
        formType: 'between',
        choices: [],
        actualValue: 0,
        minValue: 0,
        maxValue: 0,
        cron: '',
        instrumentCode: '',
        deviceId: null,
        deviceTopic: ''
      }
    }
  },
  validations: {
    formData: {
      name: {required},
      formType: {required},
      actualValue: {required},
      minValue: {required},
      maxValue: {required},
    },
    choiceFormData: {
      text: {required},
      value: {required},
    }
  },
  methods: {
    serializeInput (formData) {
      return {
        ...formData,
        choices: this.choices || []
      }
    },
    createData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$apollo.mutate({
        mutation: CREATE_GROUP_ITEM(this.templateType),
        variables: {
          docType: this.docType,
          parentId: this.parentId,
          input: this.serializeInput(this.formData)
        }
      })
      .then(res => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$emit('created', res.data.item)
        this.toggle = false
      })
      .catch(this.$toasted.global.error)
    },
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: UPDATE_GROUP_ITEM(this.templateType),
        variables: {
          docType: this.docType,
          id: this.item.id,
          input
        }
      })
      .then(res => {
        this.$toasted.global.success("แก้ไขสำเร็จ")
        this.$emit('updated', res.data.item)
        this.toggle = false
      })
      .catch(this.$toasted.global.error)
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_GROUP_ITEM(this.templateType),
        variables: {
          docType: this.docType,
          id: this.item.id
        }
      })
      .then(res => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.$emit('destroyed', res.data.item)
        this.toggle = false
      })
      .catch(this.$toasted.global.error)
    },
    moveData (id, step) {
      this.$apollo.mutate({
        mutation: MOVE_GROUP_ITEM(this.templateType),
        variables: {
          docType: this.docType,
          id,
          step
        }
      })
      .then(res => {
        this.$toasted.global.success("แก้ไขสำเร็จ")
        this.$emit('updated', res.data.item)
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    cancelData () {
      this.toggle = false
      this.$emit('cancel', true)
    },
    clearFormData () {
      this.formData.name = ''
      this.formData.formType = 'between'
      this.formData.choices = this.choices
      this.formData.actualValue = 0
      this.formData.minValue = 0
      this.formData.maxValue = 0
      this.formData.cron = ''
      this.formData.instrumentCode = ''
      this.formData.deviceId = null
      this.formData.deviceTopic = ''
    },
    createChoice () {
      this.choices.push({...this.choiceFormData})
    },
    destroyChoice (index) {
      this.choices.splice(index, 1)
    },
    setDefaultChoice () {
      const choices = this.item.choices ? this.item.choices.map(v => {
        return {text: v.text, value: v.value}
      }) : []

      this.formData.choices = choices
      if (choices.length === 0) {
        this.choices = [
          {text: 'ไม่ผ่าน', value: 0},
          {text: 'ผ่าน', value: 1},
        ]
      } else {
        this.choices = choices
      }
    }
  },
  watch: {
    toggle (value) {
      if (value && !this.isForm) {
        this.$nextTick(() => {
          this.formData.name = this.item.name
          this.formData.formType = this.item.formType || 'between'
          this.formData.actualValue = this.item.actualValue
          this.formData.minValue = this.item.minValue
          this.formData.maxValue = this.item.maxValue
          this.formData.cron = this.item.cron
          this.formData.instrumentCode = this.item.instrumentCode

          if (this.item.device?.type) {
            this.$refs.device.setGroup(this.item.device.type)
          }
          this.formData.deviceId = this.item.deviceId
          this.formData.deviceTopic = this.item.deviceTopic

          if (this.item.formType === 'choice') {
            this.setDefaultChoice()
          }
        })
      } else {
        this.clearFormData()
      }
    },
    'formData.formType': {
      handler (value) {
        if (value === 'choice' && (!this.formData.choice || this.formData.choice.length === 0)) {
          this.setDefaultChoice()
        }
      }
    }
  },
  created () {
    if (this.isForm) this.toggle = true
  },
  components: {
    DetailGroupItemDeviceInput
  }
}
</script>

<style lang="css" scoped>
</style>
